<template>
    <div>
        <div v-if="$apollo.loading">
            <b-row
                class="mt-2 d-flex align-items-center justify-content-center"
            >
                <b-col class="d-flex align-items-center justify-content-center">
                    <Transition>
                        <h1><i class="fas fa-spinner fa-spin ml-1"></i></h1>
                    </Transition>
                </b-col>
            </b-row>
        </div>
        <div v-else-if="member_invite.id != null">
            <div class="header pb-6 d-flex align-items-center">
                <!-- Mask -->
                <span class="mask bg-white opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex align-items-center">
                    <b-row>
                        <b-col lg="12"> </b-col>
                    </b-row>
                </b-container>
            </div>
            <b-container fluid class="mt-1">
                <!-- <b-row>
                    <b-col class="mt-4 mb-2">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-primary text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col sm="12">
                        <div>
                            <b-card>
                                <b-row>
                                    <b-col>
                                        <h1>
                                            Organization Invite:
                                            {{ organization.name }}
                                        </h1>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <h4>
                                            Great news! You have been invited to
                                            be a member of the "{{
                                                organization.name
                                            }}" organization.
                                        </h4>
                                        <p>
                                            As a organization member, you'll be
                                            able to lead and manage certain
                                            aspects of the organization and it's
                                            associated services.
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-="submit"
                                            class=""
                                            @click="accept_invitation()"
                                            :loading="
                                                accept_invite_button.loading
                                            "
                                            :disabled="
                                                accept_invite_button.loading ||
                                                accept_invite_button.disabled
                                            "
                                            :success="
                                                accept_invite_button.success
                                            "
                                            >Accept
                                        </base-button>
                                        <!-- <base-button
                                            type="danger"
                                            :pill="true"
                                            class=""
                                            @click="route_to('/login')"
                                            >Decline
                                        </base-button> -->
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <div class="header pb-6 d-flex align-items-center">
                <!-- Mask -->
                <span class="mask bg-white opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex align-items-center">
                    <b-row>
                        <b-col lg="12"> </b-col>
                    </b-row>
                </b-container>
            </div>
            <b-container fluid class="mt-1">
                <!-- <b-row>
                    <b-col class="mt-4 mb-2">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-primary text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col sm="12">
                        <div>
                            <b-card>
                                <b-row>
                                    <b-col>
                                        <h1>
                                            This invite is no longer available.
                                            <i
                                                class="fas fa-spinner fa-spin ml-1"
                                            ></i>
                                        </h1>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import { GET_ORGANIZATION_MEMBER_INVITE } from "@/graphql/queries";
import { UPDATE_ORGANIZATION_MEMBER_INVITE } from "@/graphql/mutations";
export default {
    name: "OrganizationMemberInvite",
    components: {},
    apollo: {
        get_organization_member_invite: {
            query: GET_ORGANIZATION_MEMBER_INVITE,
            result(res) {
                let invites = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allOrganizationMemberInvite
                    )
                );
                if (invites.length < 1) {
                    this.member_invite = {
                        id: null,
                        uid: null,
                    };
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500);
                    return;
                }
                this.member_invite = {
                    id: invites[0].id,
                    uid: invites[0].uid,
                    status: invites[0].status,
                };

                console.log(this.member_invite);

                if (this.member_invite.status != "PENDING") {
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500);
                }
                this.organization = {
                    id: invites[0].organization__id,
                    name: invites[0].organization__name,
                };
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    invite_uid: this.invite_uid,
                    status: "pending",
                };
            },
            update(data) {
                this.apollo_data.get_organization_member_invite = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_organization_member_invite: null,
            },
            member_invite: {
                id: null,
                uid: null,
                status: null,
            },
            organization: {
                name: null,
                id: null,
            },

            invite_uid: null,
            accept_invite_button: {
                loading: false,
                success: false,
                disabled: false,
            },
        };
    },
    methods: {
        get_invite_uid() {
            let invite_uid = this.$route.query.uid;

            if (invite_uid) {
                this.invite_uid = invite_uid;
                if (!this.$apollo.queries.get_organization_member_invite.skip) {
                    this.$apollo.queries.get_organization_member_invite.refetch();
                }
                graph_utils.enable_query(
                    this.$apollo.queries.get_organization_member_invite
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_organization_member_invite
                );
                this.$router.push({
                    path: "/",
                });
            }
        },
        accept_invitation() {
            this.accept_invite_button.loading = true;
            this.$apollo
                .mutate({
                    mutation: UPDATE_ORGANIZATION_MEMBER_INVITE,
                    variables: {
                        invite_id: this.member_invite.id,
                        status: "ACCEPTED",
                    },
                })
                .then(() => {
                    // TODO: CONNECT THIS
                    global_event_emitter.$emit("sidebar_reload_required");
                    this.accept_invite_button.loading = false;
                    this.accept_invite_button.disabled = true;
                    this.accept_invite_button.success = true;
                    this.$notify({
                        message: `You are now a member of the '${this.organization.name}' organization.`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });

                    setTimeout(() => {
                        this.accept_invite_button.success = false;
                    }, 1000);

                    setTimeout(() => {
                        this.$router.push({ path: `/organization/${this.organization.id}/members` });
                    }, 2000);
                })
                .catch((err) => {
                    console.log(err);
                    this.$notify({
                        message: `Could not accept the member invite for the '${this.organization.name}' organization.`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "danger",
                    });
                    this.accept_invite_button.loading = false;
                });
        },
    },
    mounted() {
        this.get_invite_uid();
    },
};
</script>

<style></style>
